export const echartOptions: {
  colorLong: string[];
  colorShort: string[];
  realtimeColor: string;
} = {
  colorLong: [
    "#285AFF",
    "#285AFFCC",
    "#285AFFB2",
    "#285AFF99",
    "#285AFF80",
    "#285AFF66",
    "#285AFF66",
    "#285AFF33",
    "#285AFF1A",
  ],
  colorShort: ["#FFE74F", "#80CDFF", "#BCF453"],

  realtimeColor: "#285AFF",
};

export class Colors {
  // PRIMARY v1.1
  static primary = "#285aff";
  static primary50 = "#e5ebff";
  static primary100 = "#bfceff";
  static primary200 = "#94adff";
  static primary300 = "#698cff";
  static primary400 = "#4873ff";
  static primary500 = "#285aff";
  static primary600 = "#2452ff";
  static primary700 = "#1e48ff";
  static primary800 = "#183fff";
  static primary900 = "#0f2eff";
  static primaryA100 = "#ffffff";
  static primaryA200 = "#f7f8ff";
  static primaryA400 = "#c4caff";
  static primaryA700 = "#abb3ff";

  static contrast50 = "#000000";
  static contrast100 = "#000000";
  static contrast200 = "#000000";
  static contrast300 = "#000000";
  static contrast400 = "#ffffff";
  static contrast500 = "#ffffff";
  static contrast600 = "#ffffff";
  static contrast700 = "#ffffff";
  static contrast800 = "#ffffff";
  static contrast900 = "#ffffff";
  static contrastA100 = "#000000";
  static contrastA200 = "#000000";
  static contrastA400 = "#000000";
  static contrastA700 = "#000000";

  // Secundario - Secondary v1.0
  static secondary = "#ed0000";
  static secondary50 = "#fde0e0";
  static secondary100 = "#fab3b3";
  static secondary200 = "#f68080";
  static secondary300 = "#f24d4d";
  static secondary400 = "#f02626";
  static secondary500 = "#ed0000";
  static secondary600 = "#eb0000";
  static secondary700 = "#e80000";
  static secondary800 = "#e50000";
  static secondary900 = "#e00000";
  static secondaryA100 = "#ffffff";
  static secondaryA200 = "#ffd4d4";
  static secondaryA400 = "#ffa1a1";
  static secondaryA700 = "#ff8888";

  static secondaryContrast50 = "#000000";
  static secondaryContrast100 = "#000000";
  static secondaryContrast200 = "#000000";
  static secondaryContrast300 = "#ffffff";
  static secondaryContrast400 = "#ffffff";
  static secondaryContrast500 = "#ffffff";
  static secondaryContrast600 = "#ffffff";
  static secondaryContrast700 = "#ffffff";
  static secondaryContrast800 = "#ffffff";
  static secondaryContrast900 = "#ffffff";
  static secondaryContrastA100 = "#000000";
  static secondaryContrastA200 = "#000000";
  static secondaryContrastA400 = "#000000";
  static secondaryContrastA700 = "#000000";

  // Escala de Grises - Neutral v0.9
  static neutral = "#ffffff";
  static neutral00 = "#f8f7f2";
  static neutral05 = "#d7d7d7";
  static neutral10 = "#a5a5a5";
  static neutral20 = "#707070";
  static neutral40 = "#374649";
  static neutral60 = "#272727";
  static neutral80 = "#03110e";

  // FONDOS
  static background = "#fff";
  static background01 = "#f7f9fa";
  static background02 = Colors.neutral00;

  // EVENTOS Y ALERTAS
  // Success - OK v1.0
  static success = "#30af43";
  static successMedium = "#59bf69";
  static successLight = "#c1e7c7";

  // Alertas - Warnings v1.0
  static warning = "#fa7921";
  static warningMedium = "#ffa965";
  static warningLight = "#ffe0ca";

  // Errores - Errors v1.0
  static error = "#eb4747";
  static errorMedium = "#f17e7e";
  static errorLight = "#ffcaca";

  // TEMATICOS
  static water20 = "#acdaec";
  static water = "#73c0de";

  // TRANSPARENCIAS
  // Transparencias Blancas - v1.0
  static transparent05 = "rgba(255, 255, 255, 0.05)";
  static transparent10 = "rgba(255, 255, 255, 0.1)";
  static transparent20 = "rgba(255, 255, 255, 0.2)";
  static transparent40 = "rgba(255, 255, 255, 0.4)";
  static transparent60 = "rgba(255, 255, 255, 0.6)";
  static transparent80 = "rgba(255, 255, 255, 0.8)";

  // Transparencias Oscuras - v1.0
  static transparentDark05 = "rgba(0, 0, 0, 0.05)";
  static transparentDark10 = "rgba(0, 0, 0, 0.1)";
  static transparentDark20 = "rgba(0, 0, 0, 0.2)";
  static transparentDark40 = "rgba(0, 0, 0, 0.4)";
  static transparentDark60 = "rgba(0, 0, 0, 0.6)";
  static transparentDark80 = "rgba(0, 0, 0, 0.8)";

  // DEGRADADOS v0.5
  static gradientPrimary = "linear-gradient(315deg, #00446f 0%, #0070b5 100%)";
  static gradientSecondary =
    "linear-gradient(315deg, #56e39f 0%, #0070b7 100%)";
  static gradientNeutral =
    "linear-gradient(180deg, #5a5a5a 0%, rgba(255, 255, 255, 0) 100%)";
  static gradient03 = `linear-gradient(170.56deg, #ffffff 62.44%, #f7f9fa 154.48%)`;
  static gradientLogin =
    "linear-gradient(180deg, #f7f9fa 0%, #ffffff 238.36%, #f7f9fa 402.23%)";

  // BOX-SHADOW SOMBRAS v1.0
  static shadowSoft = "0px 2px 2px rgba(0, 0, 0, 0.15)";
  static shadowMedium = "0px 4px 8px rgba(0, 0, 0, 0.15)";
  static shadowHard = "0px 8px 16px rgba(0, 0, 0, 0.2)";
}

class Classes {
  // Clases
  static white = {
    color: "white !important",
  };

  static grey = {
    color: Colors.neutral40,
  };

  static primary = {
    color: Colors.primary,
  };

  static secondary = {
    color: Colors.secondary,
  };

  static success = {
    color: Colors.success,
  };

  static warning = {
    color: Colors.warning,
  };

  static error = {
    color: Colors.error,
  };

  static errorColor = {
    color: Colors.error,
  };
}
